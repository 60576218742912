function NoPage() {
  return (
    <>
      <h1>Uh oh! 🤔</h1>
      <h3>Looks like you're lost!</h3>
      <p>The page you were looking for was not found. Please try again and report this issue if it reoccurs.</p>
    </>
  );
}

export default NoPage;


